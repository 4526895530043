import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'admins',
    loadChildren: () => import("../../components/admins/admins.module").then(m => m.AdminsModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import("../../components/pricing/pricing.module").then(m => m.PricingModule)
  },
  {
    path: 'home',
    loadChildren: () => import("../../components/home/home.module").then(m => m.HomeModule)
  },
  {
    path: 'promos',
    loadChildren: () => import("../../components/promos/promos.module").then(m => m.PromosModule)
  },
  {
    path: 'pushs',
    loadChildren: () => import("../../components/pushs/pushs.module").then(m => m.PushsModule)
  },
  {
    path: 'application',
    loadChildren: () => import("../../components/application/application.module").then(m => m.ApplicationModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import("../../components/analytics/analytics.module").then(m => m.AnalyticsModule)
  },
  {
    path: 'driver/:id',
    loadChildren: () => import("../../components/driver/driver.module").then(m => m.DriverModule)
  },
  {
    path: 'drivers',
    loadChildren: () => import("../../components/drivers/drivers.module").then(m => m.DriversModule)
  },
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'payments',
    loadChildren: () => import("../../components/payments/payments.module").then(m => m.PaymentsModule)
  }
];
