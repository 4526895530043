<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/"><h2>Taxishka</h2></a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Вхід</h4>
              <div class="form-group">
                <label class="col-form-label">Логін</label>
                <input class="form-control" type="text" required="" placeholder="admin" formControlName="email" />
              </div>
              <div class="form-group">
                <label class="col-form-label">Пароль</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
              </div>
              <div class="form-group mb-0">
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Passwort vergessen?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Вхід</span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
