<div class="media profile-media">
  <div class="media-body"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">
      <!-- Administrator  -->
      <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><app-feather-icons [icon]="'edit-2'"></app-feather-icons><span>Редугвання профілю</span></li> -->
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Вихід</span></li>
</ul>